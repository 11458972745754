.navLink a {
  text-decoration: none;
  color: #fff;
  font-size: 15px;
  font-family: "Poppins";
}

.colorNavLink a {
  text-decoration: none;
  color: #25c027;
  font-size: 15px;
  font-family: "Poppins";
}
