.active {
  color: #fff;
  display: block;
  background-color: #25c027;
  padding: 15px;
  border-radius: 8px;
  fill: #fff;
  text-decoration: none;
}

.inactive {
  display: block;
  color: #696969;
  fill: #25c027;
  background-color: #fff;
  padding: 15px;
  text-decoration: none;
}
