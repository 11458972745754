.input input {
  border: none;
  outline: none;
  width: 100%;
  font-size: 18px;
  color: #8c8c8c;
  font-weight: 500;
}

.selectBox {
  min-width: 23%;
}
@media only screen and (max-width: 710px) {
  .selectBox {
    width: 220px;
  }
}
@media only screen and (max-width: 520px) {
  .filterBox {
    justify-content: center;
  }
}
