.input {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.input label {
  font-family: "Sofia Pro";
  font-size: 15px;
  color: #202124;
}

.input input {
  width: 100%;
  padding: 15px 20px;
  background: #f0faf0;
  border: none;
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  border-radius: 8px;
  outline: none;
  color: #696969;
  font-size: 15px;
  font-family: "Sofia Pro";
}
.input input:focus {
  width: 100%;
  padding: 15px 20px;
  background: #ffffff;
  border: 1px solid #25c027;
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  border-radius: 8px;
  outline: none;
  color: #696969;
  font-size: 15px;
  font-family: "Sofia Pro";
}
