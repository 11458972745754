.ais-RefinementList-list {
  margin-bottom: 24px;
  /* background-color: aqua; */
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  /* overflow-y: scroll; */
  /* border: 1px solid #ccc; */
  margin-bottom: auto;
  margin-top: auto;
}
.ais-refinementlist-list ::-webkit-scrollbar-button {
  background-color: #ccc;
  display: none;
}
.ais-RefinementList-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}
.ais-SearchBox {
  width: 100%;
}
.ais-SearchBox-form {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.ais-SearchBox-submit {
  border: none;
}
.ais-SearchBox-reset {
  display: none;
}
