.education-timeline {
  padding: 0 !important;
}

.education-timeline .MuiTimelineItem-missingOppositeContent::before {
  display: none;
}

.education-timeline .education-timeline_dot {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9e0df !important;
}

.education-timeline .MuiTimelineConnector-root {
  background-color: transparent !important;
  border-left: 2px dashed #d93025;
}

.works-timeline {
  padding: 0 !important;
}

.works-timeline .MuiTimelineItem-missingOppositeContent::before {
  display: none;
}

.works-timeline .works-timeline_dot {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #DDE8F8 !important;
}

.works-timeline .MuiTimelineConnector-root {
  background-color: transparent !important;
  border-left: 2px dashed #1967D2;
}

.awards-timeline {
  padding: 0 !important;
}

.awards-timeline .MuiTimelineItem-missingOppositeContent::before {
  display: none;
}

.awards-timeline .awards-timeline_dot {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFF7E6 !important;
}

.awards-timeline .MuiTimelineConnector-root {
  background-color: transparent !important;
  border-left: 2px dashed #F9AB00;
}
