@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

@font-face {
  font-family: "Sofia Pro";
  src: url(./utilities/fonts/Sofia\ Pro\ Black\ Az.otf);
  font-weight: 900;
}

@font-face {
  font-family: "Sofia Pro";
  src: url(./utilities/fonts/Sofia\ Pro\ Black\ Italic\ Az.otf);
}
@font-face {
  font-family: "Sofia Pro";
  src: url(./utilities/fonts/Sofia\ Pro\ Bold\ Az.otf);
  font-weight: 700;
}
@font-face {
  font-family: "Sofia Pro";
  src: url(./utilities/fonts/Sofia\ Pro\ Bold\ Italic\ Az.otf);
}
@font-face {
  font-family: "Sofia Pro";
  src: url(./utilities/fonts/Sofia\ Pro\ ExtraLight\ Az.otf);
}
@font-face {
  font-family: "Sofia Pro";
  src: url(./utilities/fonts/Sofia\ Pro\ ExtraLight\ Az.otf);
}
@font-face {
  font-family: "Sofia Pro";
  src: url(./utilities/fonts/Sofia\ Pro\ ExtraLight\ Az.otf);
}
@font-face {
  font-family: "Sofia Pro";
  src: url(./utilities/fonts/Sofia\ Pro\ Light\ Az.otf);
}
@font-face {
  font-family: "Sofia Pro";
  src: url(./utilities/fonts/Sofia\ Pro\ Light\ Italic\ Az.otf);
}
@font-face {
  font-family: "Sofia Pro";
  src: url(./utilities/fonts/Sofia\ Pro\ Medium\ Italic\ Az.otf);
}
@font-face {
  font-family: "Sofia Pro";
  src: url(./utilities/fonts/Sofia\ Pro\ Medium\ Az.otf);
  font-weight: 500;
}
@font-face {
  font-family: "Sofia Pro";
  src: url(./utilities/fonts/Sofia\ Pro\ Regular\ Az.otf);
  font-weight: 400;
}
@font-face {
  font-family: "Sofia Pro";
  src: url(./utilities/fonts/Sofia\ Pro\ Regular\ Italic\ Az.otf);
}
@font-face {
  font-family: "Sofia Pro";
  src: url(./utilities/fonts/Sofia\ Pro\ Semi\ Bold\ Az.otf);
  font-weight: 600;
}
@font-face {
  font-family: "Sofia Pro";
  src: url(./utilities/fonts/Sofia\ Pro\ Semi\ Bold\ Italic\ Az.otf);
}
@font-face {
  font-family: "Sofia Pro";
  src: url(./utilities/fonts/Sofia\ Pro\ UltraLight\ Az.otf);
}
@font-face {
  font-family: "Sofia Pro";
  src: url(./utilities/fonts/Sofia\ Pro\ UltraLight\ Italic\ Az.otf);
}
@font-face {
  font-family: "Sofia Pro";
  src: url(./utilities/fonts/Sofia\ Pro\ UltraLight\ Az.otf);
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hidescrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hidescrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.css-1eax6av-MuiPaper-root-MuiAccordion-root:before {
  content: none !important;
}
