.active {
  text-decoration: none;
  font-family: "Sofia Pro";
  font-size: 15px;
  color: #fff;
  font-size: 18px;
  background-color: #25c027;
  padding: 14px 24px;
  border-radius: 8px;
}

.inactive {
  text-decoration: none;
  font-family: "Sofia Pro";
  font-size: 15px;
  color: #24bd2c;
  font-size: 18px;
  background-color: #e2f5e2;
  color: #24bd2c;
  padding: 14px 24px;
  border-radius: 8px;
}
