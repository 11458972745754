/* Hide scrollbar for Chrome, Safari and Opera */
.hidescrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hidescrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.input {
  padding: 20px;
  background: #f0faf0;
  border-radius: 8px;
  font-family: "Sofia Pro";
  font-size: 15px;
  color: #696969;
  display: flex;
  align-items: center;
}

.input input {
  border: none;
  outline: none;
  width: 100%;
  background: transparent;
  color: #696969;
}
