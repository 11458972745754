.link a {
  text-decoration: none;
  display: block;
  padding: 10px;
  color: #fff;
  background-color: #25c027;
  margin-bottom: 10px;
  border-radius: 8px;
  font-size: 15px;
  font-family: "Poppins";
  text-align: center;
}

.sidebar {
  width: 100%;
}
