.status-active {
  width: 50%;
  font-size: 15px;
  font-family: "Sofia Pro";
  background-color: #24bd2c;
  color: #fff;
  padding: 14px 24px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  fill: #fff;
}
.status-inactive {
  width: 50%;
  font-size: 15px;
  font-family: "Sofia Pro";
  background-color: #F0FAF0;
  color: #24bd2c;
  padding: 14px 24px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  fill: #24bd2c;
}
